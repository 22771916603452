import { useEffect } from 'react';

import { localStorageService } from "../services/local_storage.service";

import { generateUniqueID } from "../functions/uniq_id_generator";

export const useLoadKountSessionScript = () => {
    useEffect(() => {
        const uniqueId = generateUniqueID();
        localStorageService.setItem('kount_session_id', uniqueId)

        const kountScript = document.createElement('script');

        kountScript.src = `https://tst.kaptcha.com/collect/sdk?m=100261&s=${uniqueId}`;
        kountScript.async = false;

        document.body.appendChild(kountScript);
    }, []);
}