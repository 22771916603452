import React from "react";
import { Frames, CardNumber, ExpiryDate, Cvv } from "frames-react";

import { PrimaryButton } from "../../buttons/primary_button";
import { CardHeader } from "./card_header";
import { InfoBlock } from "../../info_block";

import { useCheckoutForm } from "./checkout_form.hook";
import { base, placeholder, valid, focus, invalid } from "./inline_styles";
import lockIcon from '../../../assets/paywall/lock.svg';
import blackLock from '../../../assets/paywall/black_lock.svg';
import visa from '../../../assets/paywall/visa_card.svg';
import mastercard from '../../../assets/paywall/mastercard_card.svg';
import amex from '../../../assets/paywall/amex_card.svg';
import discover from '../../../assets/paywall/discover_card.svg';
import diners from '../../../assets/paywall/diners_card.svg';
import errorCard from '../../../assets/paywall/error_card.svg';
import emptyCard from '../../../assets/paywall/empty_card.svg';
import closeIcon from '../../../assets/paywall/close_icon.svg';

import { useStyles } from "./styles";
import { TranslationPaywallProps } from '../index';
import {theming} from "../../../theme";

type FormProps = {
    publicKey: string,
    countryCode: string,
    error: string,
    handleClose: () => any,
    isUpdateForm?: boolean,
    onSubmit: any,
    formText: any,
    translation: TranslationPaywallProps,
    checkoutModalTitleClass?: string
}

const CARD_TYPES = {
    'Visa': visa,
    'Mastercard': mastercard,
    'American Express': amex,
    'Diners Club': diners,
    'Discover': discover
};

export const CheckoutForm = ({
    publicKey,
    countryCode,
    onSubmit,
    isUpdateForm,
    handleClose,
    formText,
    error,
    translation,
    checkoutModalTitleClass = ''
}: FormProps) => {
    const theme = theming.useTheme();
    const {
        inputWrapper, inputName, inputErrorWrapper,
        form, buttonContent, cvvInput, errorText, close,
        cvvWrapper, zipCodeInput, button, cardIconError, cardIcon,
        errorBlock
    } = useStyles({ theme });
    const {
        inputText: {
            cardNumberPlaceholder, expiryMonthPlaceholder, expiryYearPlaceholder,
            cvvPlaceholder, nameOfCardPlaceholder, billingPostcodePlaceholder
        }, modalPrimaryButtonText: {
            btnUpdate, btnStartTrail
        }, inputErrors, modalTitle
    } = translation;

    const {
        cardName,
        cardValidationChanged, paymentMethodChanged, frameValidationChanged,
        handlePostalCodeChange, handleChangeName, handleSubmit,
        name, country, postalCode, loading, disabledBtn, errorMsgCard, errorMsgDate, errorMsgCvv,
        errorMsgCardEmpty, errorMsgDateEmpty, errorMsgCvvEmpty, loadedScript, errorVisible
    } = useCheckoutForm(countryCode, onSubmit, inputErrors);


    if (!loadedScript) return null;

    return (
        <div className={form}>
            <img
                onClick={handleClose}
                className={close}
                src={closeIcon}
                alt=""
            />
            <CardHeader
                titleClass={checkoutModalTitleClass}
                headerTitle={modalTitle}
            />
            {(error && errorVisible) && (
                <InfoBlock
                    className={errorBlock}
                    variant="error"
                    text={error}
                />
            )}
            <Frames
                config={{
                    publicKey,
                    localization: {
                        cardNumberPlaceholder: cardNumberPlaceholder,
                        expiryMonthPlaceholder: expiryMonthPlaceholder,
                        expiryYearPlaceholder: expiryYearPlaceholder,
                        cvvPlaceholder: cvvPlaceholder,
                    },
                    style: { base, placeholder, invalid, focus, valid },
                    cardholder: { name }
                }}
                cardValidationChanged={cardValidationChanged}
                paymentMethodChanged={paymentMethodChanged}
                frameValidationChanged={frameValidationChanged}
                cardTokenized={() => {}}
                cardTokenizationFailed={(e: any) => console.log(e) }
            >
                <div>
                    <div className={`${inputWrapper} ${inputName}`}>
                        <input
                            value={name}
                            onChange={handleChangeName}
                            placeholder={nameOfCardPlaceholder}
                            type='text'
                        />
                    </div>
                </div>
                <div className={inputErrorWrapper}>
                    <CardNumber />
                    {errorMsgCard && errorMsgCardEmpty
                        ? <img className={cardIconError} src={errorCard} alt=""/>
                        //@ts-ignore
                        : <img className={cardIcon} src={CARD_TYPES[cardName] || emptyCard} alt=""/>
                    }
                    {errorMsgCard && errorMsgCardEmpty && (
                        <p className={errorText}>{errorMsgCard}</p>
                    )}
                </div>
                <div className={cvvWrapper}>
                    <div className={inputErrorWrapper}>
                        <ExpiryDate />
                        {errorMsgDate && errorMsgDateEmpty && (
                            <p className={errorText}>{errorMsgDate}</p>
                        )}
                    </div>
                    <div className={`${cvvInput} ${inputErrorWrapper}`}>
                        <Cvv />
                        {errorMsgCvv && errorMsgCvvEmpty && (
                            <p className={errorText}>{errorMsgCvv}</p>
                        )}
                    </div>
                </div>
                {country === "US" && (
                    <div className={inputWrapper}>
                        <input
                            className={zipCodeInput}
                            value={postalCode}
                            onChange={handlePostalCodeChange}
                            placeholder={billingPostcodePlaceholder}
                            type="number"
                        />
                    </div>
                )}
                {formText && formText}
                <PrimaryButton
                    className={button}
                    loading={loading}
                    disabled={disabledBtn}
                    onClick={handleSubmit}
                    //@ts-ignore
                    label={
                        <div className={buttonContent}>
                            <img src={disabledBtn ? blackLock : lockIcon} alt=""/>
                            {isUpdateForm ? btnUpdate : btnStartTrail}
                        </div>
                    }
                />
            </Frames>
        </div>
    )
}