export const scriptLoader = (cb: (bool: boolean) => any) => {
    const script = document.createElement('script');

    script.src = 'https://js.braintreegateway.com/web/3.81.0/js/client.min.js';

    script.onload = () => {
        const script = document.createElement('script');

        script.src = 'https://js.braintreegateway.com/web/3.81.0/js/paypal-checkout.min.js';

        script.onload = () => cb(true);

        document.head.appendChild(script);
    }

    document.head.appendChild(script);
}