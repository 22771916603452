// node modules
import { createContext, ReactNode } from 'react';
import { createTheming } from 'react-jss';

// local files
import { palette, Palette } from "./palette";
import { primaryButton, checkButton, coloredButton } from "./buttons";
import { typography } from "./typography";
import { font, Font } from "./font";

export type ThemeProps = {
    palette: Palette,
    font?: Record<string, Font>,
    primaryButton?: (prop: ThemeProps) => any,
    coloredButton?: (prop: ThemeProps) => any,
    checkButton?: (prop: ThemeProps) => any,
    typography?: any,
    overwrite?: any
}

export type Theme = {
    theme: ThemeProps,
    adornmentEnd?: string | ReactNode,
    adornmentStart?: string | ReactNode,
    type?: string
}

export const defaultTheme = {
    palette,
    font,
    primaryButton,
    coloredButton,
    checkButton,
    typography
}

const ThemeContext = createContext<ThemeProps>(defaultTheme);

export const theming = createTheming(ThemeContext);
