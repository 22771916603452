import React, {useEffect, useState} from "react";

import { PayPalProps } from "../";
import { useStyles } from "./styles";
import {PrimaryButton} from "../../../buttons/primary_button";

export const Content = ({
    onSubmit,
    braintreeAuthorization,
    setPayPalLoaded,
    paypalButtonProps
}: PayPalProps) => {
    const [loading, setLoading] = useState(false);
    const { payPal, payPalLoading, hide } = useStyles();


    useEffect(() => {
        //@ts-ignore
        window.braintree?.client.create({
            authorization: braintreeAuthorization,
        }, function (clientErr: any, clientInstance: any) {

            if (clientErr) {
                console.error('Error creating client:', clientErr);
                return;
            }
            //@ts-ignore
            window.braintree?.paypalCheckout.create({
                client: clientInstance,
            }, function (_:any, paypalCheckoutInstance: any) {
                paypalCheckoutInstance.loadPayPalSDK({
                    currency: 'USD',
                    intent: 'capture',
                    vault: true,
                }, function () {
                    //@ts-ignore
                    window.paypal?.Buttons({
                        style: paypalButtonProps,
                        //@ts-ignore
                        fundingSource: window.paypal.FUNDING.PAYPAL,
                        createBillingAgreement: function () {
                            return paypalCheckoutInstance.createPayment({
                                flow: 'vault', // Required
                                enableShippingAddress: true,
                            });
                        },
                        onApprove: function (data: any) {
                            setLoading(true)
                            return paypalCheckoutInstance.tokenizePayment(data, (_: any, payload: any) =>
                                onSubmit({ ...payload, type: 'payPal' }).then(() => {
                                    setLoading(false);
                                })
                            );
                        },
                        onCancel: function (data: any) {
                            //@ts-ignore
                            console.log('PayPal payment canceled', JSON.stringify(data, 0, 2));
                        },
                        onError: function (err: any) {
                            console.error('PayPal error', err);
                        }
                    }).render('#paypal-button')
                        .then(() => {
                            console.log('paypal ready')
                            setPayPalLoaded(true)
                        });
                });
            });
        });
    }, []);

    return <>
        <PrimaryButton className={`${payPalLoading} ${!loading? hide: ''}`} loading={loading} label={''} />
        <div className={`${payPal} ${loading? hide: ''}`} id="paypal-button"/>
    </>
}
