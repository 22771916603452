import {useLayoutEffect, useState} from "react";
import { Frames } from "frames-react";

type inputErrorsTypes = {
    errorCard: string,
    errorDate: string,
    errorCvv: string
}

export const useCheckoutForm = (country: string, onSubmit: any, inputErrors: inputErrorsTypes) => {
    const [loading, setLoading] = useState(false);
    const [postalCode, setPostalCode] = useState('');
    const [name, setName] = useState('');
    const [isValid, toggleValid] = useState(false);
    const [cardName, setCardName] = useState('');
    const [token, setToken] = useState('');
    const [errorMsgCard, setErrorCard] = useState('');
    const [errorMsgDate, setErrorDate] = useState('');
    const [errorMsgCvv, setErrorCvv] = useState('');
    const [errorMsgCardEmpty, setErrorCardEmpty] = useState(false);
    const [errorMsgDateEmpty, setErrorDateEmpty] = useState(false);
    const [errorMsgCvvEmpty, setErrorCvvEmpty] = useState(false);
    const [billingPostcode, setBillingPostcode] = useState('');
    const [loadedScript, setLoadedScript] = useState(false);
    const [errorVisible, setErrorVisible] = useState(false)

    const handlePostalCodeChange = ({ target: { value } }: any) => setPostalCode(value);
    const handleChangeName = ({ target: { value } }: any) => setName(value);
    const cardValidationChanged = (e: any) => {
        toggleValid(e.isValid)
    };
    const frameValidationChanged = (e: {element: string, isValid: boolean, isEmpty: boolean}) => {
        if (e.element === 'card-number' && !e.isValid && !e.isEmpty) {
            setErrorCard(inputErrors.errorCard)
            setErrorCardEmpty(true)
        }
        if (e.element === 'expiry-date' && !e.isValid && !e.isEmpty) {
            setErrorDate(inputErrors.errorDate)
            setErrorDateEmpty(true)
        }
        if (e.element === 'cvv' && !e.isValid && !e.isEmpty) {
            setErrorCvv(inputErrors.errorCvv)
            setErrorCvvEmpty(true)
        }
        if (e.element === 'card-number' && e.isValid || e.element === 'card-number' && e.isEmpty && !e.isValid) {
            setErrorCard('')
            setErrorCardEmpty(false)
        }
        if (e.element === 'expiry-date' && e.isValid || e.element === 'expiry-date' && e.isEmpty && !e.isValid) {
            setErrorDate('')
            setErrorDateEmpty(false)
        }
        if (e.element === 'cvv' && e.isValid || e.element === 'cvv' && e.isEmpty && !e.isValid) {
            setErrorCvv('')
            setErrorCvvEmpty(false)
        }
    }
    const paymentMethodChanged = ({ paymentMethod }: any) => setCardName(paymentMethod);

    const disabledBtn = !isValid || (country === 'US' && !postalCode) || !name;
    const handleSubmit = () => {
        if (disabledBtn || loading) {
            return;
        }

        setLoading(true);

        if(token) {
            Frames.enableSubmitForm();
        }

        Frames.submitCard()
            .then(({ token}) => {
                setToken(token);

                onSubmit({ token, name, postalCode, type: 'card' })
                    .then(() => {
                        setLoading(false);
                        setErrorVisible(true);
                    })
            })
    }

    useLayoutEffect(() => {
        const script = document.createElement('script');

        script.src = 'https://cdn.checkout.com/js/framesv2.min.js';

        document.head.appendChild(script);

        script.onload = () => {
            setLoadedScript(true);
        }
    }, []);


    return {
        cardName,
        cardValidationChanged, paymentMethodChanged, frameValidationChanged,
        handlePostalCodeChange, handleChangeName, handleSubmit,
        name, country, postalCode, loading, disabledBtn,
        errorMsgCard, errorMsgDate, errorMsgCvv, errorMsgCardEmpty, errorMsgDateEmpty, errorMsgCvvEmpty,
        setBillingPostcode, billingPostcode, loadedScript, errorVisible
    }
}