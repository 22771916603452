// node modules
import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    root: {
        borderRadius: '.5rem',
        transition: ({ theme: { animationTime } }) => `background-color ${animationTime}ms, color ${animationTime}ms`,
        background: ({ theme: { palette } }: any) => palette.light,
        color: ({ theme: { palette } }) => palette.black,
    },
    rootSelected: {
        background: ({ theme: { palette } }) => palette.primary,
        color: () => "#ffffff",

        '&:active': {
            boxShadow: ({ theme: {palette} }) => palette.boxShadow
        }
    },
    "@media (max-width: 365px)": {
        root: {
            "& >p[class^='title'], &>p[class*=' title'], & >p[class^='body'], &>p[class*=' body']": {
                '& >br': {
                    display: 'none',
                },
                '& >span br': {
                    display: 'none',
                }
            },
        }
    },
})
