import { ThemeProps } from "./";
import { alignment } from "./alignment";

export const primaryButton = ({ palette, overwrite }: ThemeProps) => ({
    borderRadius: '2em',
    background: palette.primary,
    color: palette.light,
    transition: 'background .3s, color .3s, box-shadow 2s',

    '&:hover': {
        background: palette.primary_dark
    },
    '&:active': {
        boxShadow: palette.boxShadow
    },
    ...overwrite?.primaryButton
});

export const coloredButton = ({ palette }: ThemeProps) => ({
    borderRadius: '8px',
    height: '56px',
    background: palette.primary,
    color: palette.light,
    transition: 'background .3s, color .3s, box-shadow 2s',
    '&>p': {
        fontSize: '18px'
    },
    '&:hover': {
        // background: palette.primary_dark
    },
    '&:active': {
        boxShadow: palette.boxShadow
    }
});

export const checkButton = ({ palette }: ThemeProps) => ({
    ...alignment.betweenCenter,
    '& $checkWrapper': {
        borderRadius: '50%',
        ...alignment.center,
        marginLeft: 8,
        minWidth: 24,
        width: 24,
        height: 24,
        background: palette.white,
        position: 'absolute',
        right: '18px',
    },
    '& $checkIconCl': {
        width: 12,
        '& path': {
            color: '#066fde'
        }
    },
    '& $childrenStyle': {
        paddingRight: '32px'
    },
    '& $centeredChildren': {
        width: '100%',
        paddingLeft: '32px',
        "@media (max-width: 365px)": {
            paddingLeft: 0
        },
    }
});
